/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

#background-video {
  width: 100%;
  z-index: -1;
  object-fit: cover;
  height: 100%;
  /* opacity: 0.6; */
  /* width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 568px;
  width: 100%;
  /* background-color: black; */
  background: rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  z-index: 1;
}

.logo {
  height: 3rem;
  margin-top: 0.5rem;
}

/* Video Section  Starts*/
.video_textBlock {
  position: absolute;
  /* width: 1140px; */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
}

.content-1 {
  font-size: 32.43px;
  line-height: 40px;
  color: #002677;
  font-weight: 1000;
  font-family: "Roboto";
}

.content-2 {
  color: #5a5a5a;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: "Roboto";
}
/* Video Section  Ends*/

.section-heading {
  color: #002677;
  font-weight: 1000;
  font-size: 41.04px;
  line-height: 52px;
  letter-spacing: 0;
  text-align: center;
}

.section-p {
  color: #002677;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.section p {
  color: #5a5a5a;
  font-size: 18px;
  line-height: 24px;
}

/* .section-image {
  margin-top: auto;
} */
.section-image {
  width: 100%;
  border-radius: 8px;
}

.section-testimonials {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.7)),
      to(rgba(0, 0, 0, 0.7))
    ),
    url(../public/img/news-background.jpg);
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../public/img/news-background.jpg);
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

.section-news {
  color: #fff;
  font-weight: 1000;
  font-size: 41.04px;
  line-height: 52px;
  letter-spacing: 0;
  text-align: center;
}

/* News */
.news-1 {
  padding: 51px 40px 0;
  margin-bottom: 21px;
}

.news-eyebrow {
  font-weight: bold;
  color: #002677;
  line-height: 20px;
  margin-bottom: 8px;
}

.news_heading {
  font-size: 20.25px;
  line-height: 24px;
  margin-bottom: 10px;
  font-weight: 1000;
  color: #00237c;
}

.news__bodycopy {
  font-size: 18px;
  line-height: 24px;
  color: #5a5a5a;
}

/* contact us */
.contact-box {
  /* margin: 48px; */
}

.contact-heading {
  color: #002677;
  text-align: center;
  /* font-weight: 1000; */
}

.contact-box h3 {
  color: #002677;
}

/* Footer Section*/
#footer {
  /* background: #fff; */
  /* background-color: #f4f7f7; */
  /* padding: 10px 10px;
  display: flex;
  text-align: left; */
  padding-bottom: 32px;
}
/* #footer p {
  color: #888;
  font-size: 14px;
}
#footer a {
  color: #608dfd;
}
#footer a:hover {
  border-bottom: 2px solid #608dfd;
} */

#footer .copywrite {
  flex: 1;
}

.footer-copyright {
  font-family: "Open Sans", sans-serif;
  color: #171819;
}

.footer-detail {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
}

.footer-additional-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.footer-additional-link {
  margin-left: 50px;
  font-weight: 700;
  letter-spacing: normal;
}

.footer-additional-links a {
  background-color: transparent;
  color: #171819;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
}
.footer-line {
  width: 100%;
  height: 1px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #eee;
}

.socialMediaLink {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 991px) {
  .footer-detail {
    /* padding-right: 15px;
    padding-left: 15px; */
  }
}

@media screen and (max-width: 479px) {
  .footer-detail {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-additional-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .footer-copyright {
    margin-top: 50px;
  }

  .footer-additional-link {
    position: relative;
    margin-left: 0;
  }
}
